'use strict';

$(document).on('submit', '.trackorder', function (e) {
    e.preventDefault();
    var url = $(this).attr('action');
    var form = $(this);
    var redirectURL = window.location.href;

    $('.trackorder').find('.invalid-feedback').text('');
    $('.trackorder').find('.invalid-feedback').hide()

    $(".trackorder button").addClass("is__loading");

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $(form).serialize(),

        success: function (data) {
            $(".trackorder button").removeClass("is__loading");

            if(data.success) {
                window.location.href = data.redirectUrl;
            } else {
                $(".trackorder button").removeClass("is__loading");
                $('.trackorder').find('.invalid-feedback.title').show()
                $('.trackorder').find('.invalid-feedback.subtitle').show()
                $('.trackorder').find('.invalid-feedback.title').text(data.errorMessage.title);
                $('.trackorder').find('.invalid-feedback.subtitle').text(data.errorMessage.subtitle);
            }

        },
        error: function (err) {}
    });
});

$(document).ready(function() {
    // qr code pix
    function startCountdown($this) {
        var totalTime = 1800;
        var $generateBtn = $this;

        console.log($generateBtn);

        var $parent = $generateBtn.closest('.dataOrder-container__item');
        var $qrcodeContainer = $parent.find('.qrcodeContainer');

        var $countdown = $parent.find('.countdown');

        $generateBtn.addClass('is__loading');

        setTimeout(() => {
            $generateBtn.addClass('d-none');
            $qrcodeContainer.removeClass('d-none');

            var countdown = setInterval(function() {
                var minutes = Math.floor(totalTime / 60);
                var seconds = totalTime % 60;

                minutes = minutes < 10 ? '0' + minutes : minutes;
                seconds = seconds < 10 ? '0' + seconds : seconds;

                $countdown.text(minutes + ':' + seconds);

                if (totalTime <= 0) {
                    clearInterval(countdown);
                    $qrcodeContainer.addClass('d-none');
                    $generateBtn.removeClass('d-none');
                    $generateBtn.removeClass('is__loading');
                }

                totalTime--;
            }, 1000);
        }, 1000);
    }
    $('.generateBtn').on('click', function() {
        var $this = $(this)
        startCountdown($this);
    });
    $('.copyBtn').on('click', function() {
        $(this).addClass('is__loading');
        var $this = $(this);

        var $parent = $this.closest('.dataOrder-container__item');
        var inputValue = $parent.find('.pixInput');
        navigator.clipboard.writeText(inputValue).then(function() {
            $this.removeClass('is__loading');
            $('.container-popUpCopy').fadeIn(800).fadeOut();
        }).catch(function(err) {
            $this.removeClass('is__loading');
            console.error('Erro ao copiar para a área de transferência: ', err);
        });
    });

    $('.collapse-toggle').on('click', function() {
        var $this = $(this);
        var $icon = $this.find('i');

        if ($this.attr('aria-expanded') === 'true') {
            // Quando o accordion está expandido
            $this.html('Abrir <i class="icon-chevron-down"></i>');
        } else {
            // Quando o accordion está colapsado
            $this.html('Fechar <i class="icon-chevron-up"></i>');
        }
    });
});
